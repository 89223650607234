import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import Card from './Card';

const feature = [
	{title:"Hermitage Horticultural Society and Gardening Club",
	text:"This website was built using WordPress for a club run by family members. They wanted to have a website to attract new members, display information about the shows and competitions, and have somewhere for members to see the latest news. There is also a photo gallery.",
	link:"https://hermitagehortsoc.co.uk/"},
	{title:"Template A",
	text:"I wanted to create a website template that would be ideal for a professional looking to start establishing their online presence. As such, the design of this website is stylish but doesn't have any fancy features, making it very fast.",
	link:"https://template-a.plumdev.co.uk/"},
	{title:"Template B",
	text:"This template would fit a small business who have a small number of services that they would like to display information about. Again, a lack of fancy features makes this website fast, but the full-width parallax images also make it attractive.",
	link:"https://template-b.plumdev.co.uk/"},
]

// Replace test data with your own
const features = Array.apply(null, Array(3)).map(function (x, i) {
	return {
		id: i,
		title: feature[i].title,
		text: feature[i].text,
		link: feature[i].link,
	};
});

export default function Websites() {
  return (
    <Box p={4} pt={{ base: 20, md: 28 }} id="websites">
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={'3xl'}>Websites</Heading>
      </Stack>
      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
          {features.map((feature) => (
            <HStack key={feature.id} align={'top'} justify="center">
              <VStack align={'start'}>
				<Card
					title = {feature.title}
					text = {feature.text}
					link = {feature.link}
				/>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}