import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import Card from './Card';

const feature = [
	{title:"Minesweeper",
	text:"I wanted my next app to be slightly more challenging, so I decided to use some of the components that I have already created to try Minesweeper. This project ended up not being too difficult, one challenge I encountered was how to handle onClick when the revealed cell is 0, which required calling a function recursively to display all the safe cells nearby. Another challenge was how to handle right clicks for flagging cells, which was handled with event.nativeEvent.button to split up handleClick into left click and right click.",
	link:"https://minesweeper.plumdev.co.uk/"},
	{title:"Gameload",
	text:"Now I had a game that worked with several different options, I wanted to spice up the user interface for it. I used React.Bootstrap to style the different elements, adding in an offcanvas menu to display the previous moves, and a modal with a form so a new game with different conditions could be started. This is where I began to understand the difference between Class and Function in React.",
	link:"https://gameload.plumdev.co.uk/"},
	{title:"Connect4",
	text:"Next, I wanted to try something slightly more challenging, without straying too far from TicTacToe. Connect4 was perfect for this; and as part of this I also wanted to make the board modular so I could specify the total amount of columns, rows and winning squares and it would still work. The biggest challenge was working out the victory condition, as checking the board for every possible winning condition would get slow. Instead, I needed to create a double loop to check the possible squares around the square just clicked.",
	link:"https://connect4.plumdev.co.uk/"},
	{title:"TicTacToe",
	text:"A basic game of TicTacToe using ReactJS. This is the starting project recommended when learning React. This contains the extra improvements, such as displaying the location of each move, bolding the currently selected move, using loops to create the board, and highlighting the winning squares.",
	link:"https://tictactoe.plumdev.co.uk/"},
]

// Replace test data with your own
const features = Array.apply(null, Array(4)).map(function (x, i) {
	return {
		id: i,
		title: feature[i].title,
		text: feature[i].text,
		link: feature[i].link
	};
});

export default function Apps() {
  return (
    <Box p={4} pt={{ base: 20, md: 28 }} id="apps">
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={'3xl'}>Apps</Heading>
      </Stack>
      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={5}>
          {features.map((feature) => (
            <HStack key={feature.id} align={'top'} justify="center">
              <VStack align={'start'}>
				<Card
					title = {feature.title}
					text = {feature.text}
					link = {feature.link}
				/>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}