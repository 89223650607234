import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconProps,
  useColorModeValue,
} from '@chakra-ui/react';
import heroimage from './hero-image.jpg';

export default function Hero() {
  return (
    <Container maxW={'7xl'} id="home">
      <Stack
        align={'center'}
        spacing={{ base: 16, md: 10 }}
        pt={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              position={'relative'}
			  zIndex={0}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'pink.600',
                zIndex: -1,
              }}>
              Daniel Plummer
            </Text>
            <br />
            <Text as={'span'} color={'pink.600'}>
              Portfolio & Projects
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            Hello, and thank you for visiting. This is my portfolio containing just some of the projects I have worked on, along with other small designs and programs that I have developed. If you'd like to get in touch, please use the contact form below.
          </Text>
          <Flex
			flex={1}
            justify={'center'}
			>
			<Button
			colorScheme="pink"
			bg="pink.600"
			color="white"
			_hover={{bg: 'pink.700',}}
			as="a"
			href="#contact">
				Contact Me
			</Button>
          </Flex>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Blob
            w={'150%'}
            h={'150%'}
            position={'absolute'}
            top={'-20%'}
            left={0}
            zIndex={0}
            color={useColorModeValue('pink.700', 'pink.600')}
          />
          <Box
            position={'relative'}
            height={'auto'}
			maxHeight={'300px'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}>
            <Image
              alt={'Hero Image'}
              fit={'contain'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={heroimage}
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};