import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const feature = [
	{title:"Web Development",
	text:"Using technologies including HTML, CSS, JS, jQuery, PHP, and MySQL, I have designed and developed many websites. I also have experience with WordPress, and I am self-taught in ReactJS."},
	{title:"IT Support & Sys Admin",
	text:"I can provide hardware and software support for both Windows and Macs, and I have experience with managing Windows Server, Active Directory, and Office 365."},
	{title:"Networking",
	text:"I have experience in network design, maintenance and installation, and training with technologies including Cisco."},
	{title:"SEO & Online Marketing",
	text:"Using SEO techniques, I can help improve search engine rankings, in turn increasing traffic and conversions."},
]

// Replace test data with your own
const features = Array.apply(null, Array(4)).map(function (x, i) {
	return {
		id: i,
		title: feature[i].title,
		text: feature[i].text,
	};
});

export default function Features() {
  return (
    <Box p={4} pt={{ base: 20, md: 28 }} id="skills">
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={'3xl'}>Skills</Heading>
        <Text color={'gray.600'} fontSize={'xl'}>
          I have worked in IT, SEO, and web development for over 6 years, giving me a broad range of skills and experience. This allows me to fill numerous roles, including support, networking, sys admin, web development and marketing.
        </Text>
      </Stack>
      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={'top'}>
              <Box color={'pink.600'} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={'gray.600'}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}