import {
  Box,
  chakra,
  Container,
  Stack,
  useColorModeValue,
  VisuallyHidden,
  Image,
  Heading
} from '@chakra-ui/react';
import { FaLinkedin, FaGithub, FaEnvelope} from 'react-icons/fa';
import { ReactNode } from 'react';
import logo from './plumdev.png';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
	  target="_blank"
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
		<Stack direction={'row'} alignItems="center">
			<Image
			  alt={'Plumdev Logo'}
			  fit={'contain'}
			  align={'center'}
			  src={logo}
			/>
			<Heading fontSize="2xl" fontFamily="monospace" fontWeight="bold">
			  PLUM.DEV
			</Heading>
		</Stack>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Email'} href={'mailto:dplummer1995@hotmail.co.uk'}>
            <FaEnvelope />
          </SocialButton>
          <SocialButton label={'Github'} as="a" href={'https://github.com/PlumdotDev'}>
            <FaGithub />
          </SocialButton>
          <SocialButton label={'LinkedIn'} as="a" href={'https://www.linkedin.com/in/daniel-plummer-b9a05923a/'}>
            <FaLinkedin />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}