import {
  Box,
  Center,
  Heading,
  Button,
  Text,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Card(props) {
	return (
		<Center py={6}>
			<Box
			maxW={'445px'}
			w={'full'}
			bg={useColorModeValue('white', 'gray.900')}
			boxShadow={'2xl'}
			rounded={'md'}
			p={6}
			overflow={'hidden'}>
				<Stack>
					<Heading
					color={useColorModeValue('gray.700', 'white')}
					fontSize={'2xl'}
					fontFamily={'body'}>
						{props.title}
					</Heading>
					<Text color={'gray.500'}>
						{props.text}
					</Text>
					<Button
					colorScheme="pink"
					bg="pink.600"
					color="white"
					_hover={{bg: 'pink.700',}}
					as="a"
					href={props.link}
					target="_blank">
						View Project
					</Button>
				</Stack>
			</Box>
		</Center>
	);
}